<template>
  <div>
    <a-modal
      v-model="isShowFirstStepModal"
      centered
      :width="800"
      :mask-closable="false"
      ok-text="下一步"
      :ok-button-props="{ props: { loading: firstStepSubmitting } }"
      title="VPDN分组配置"
      @ok="handleValidate"
    >
      <a-row>
        <a-form
          class="simiot-compact-form"
          :form="firstStepForm"
          @submit="handleValidate"
        >
          <a-col :span="24">
            <a-form-item
              :wrapper-col="{ span: 24 }"
            >
              <common-card-input
                :rows="15"
                ref="iccidInput"
                v-decorator="['iccids', {
                  initialValue: { data: this.iccids },
                  rules: [{ validator: checkIccids }],
                }]"
              />
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-modal>

    <a-modal
      v-model="isShowSubmitModal"
      centered
      :width="600"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="VPDN分组配置"
      @ok="handleSubmit"
    >
      <a-row>
        <a-form
          class="simiot-compact-form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          :form="submitForm"
          @submit="handleSubmit"
        >
          <a-form-item label="已选择卡数">
            <a-input
              disabled
              v-model="this.validIccids.length"
            />
          </a-form-item>

          <a-form-item label="VPDN分组ID">
            <a-select
              @change="handleVpdnChange"
              v-decorator="['vpdn_id', {
                rules: [
                  { required: true, message: '请选择VPDN分组ID' }
                ]
              }]"
              :loading="loadingVpdnOptions"
              allow-clear
            >
              <a-select-option
                v-for="option in vpdnOptions"
                :key="option.vpdn_id"
                :value="option.vpdn_id"
              >
                {{ option.vpdn_id }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="VPDN分组名称">
            <a-input disabled v-model="vpdnMap.name" />
          </a-form-item>

          <a-form-item v-if="submitForm.getFieldValue('vpdn_id')!='99999_E'&& submitForm.getFieldValue('vpdn_id')!='99999_A'" label="VPDN IP地址">
            <a-textarea
              disabled
              style="height: 100px"
              v-model.trim="vpdnMap.ips"
              placeholder=""
            />
            <div>总数：{{ vpdnMap.ips_count }}</div>
          </a-form-item>

          <a-form-item
            label="备注"
            extra="*修改分组ID后则该卡可访问的IP地址会更新为最新分配的分组ID的IP地址，此操作可能会导致网络异常。"
          >
            <a-textarea
              v-decorator="['remark', {
                rules: [
                  { max: 200, message: '最多200个字符' },
                ]
              }]"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-item>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { allSimCardsUpdateVpdn, validateAllSimCardsUpdateVpdn } from '@/api/batch_operation'
import CommonCardInput from '@/components/CardInput/Common'
import { findVpdnOptions } from '@/api/common_options'

export default {
  name: 'BatchOperationUpdateVpdn',
  components: { CommonCardInput },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    iccids: {
      type: Array,
      default() {
        return []
      }
    },
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    const isShowFirstStepModal = this.visible
    return {
      loadingVpdnOptions: false,
      vpdnOptionsMap: {},
      vpdnOptions: [],
      firstStepSubmitting: false,
      vpdnMap: {},
      submitting: false,
      validIccids: [],
      isShowFirstStepModal: isShowFirstStepModal,
      isShowSubmitModal: false,
      firstStepForm: this.$form.createForm(this, { name: 'update_vpdn_first_step' }),
      submitForm: this.$form.createForm(this, { name: 'update_vpdn_submit' })
    }
  },
  watch: {
    // 步骤中任何一个弹窗打开，则 isShow 应为 true
    isShowFirstStepModal(val) {
      this.isShow = val || this.isShowSubmitModal
    },

    isShowSubmitModal(val) {
      this.isShow = val || this.isShowFirstStepModal
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchVpdnOptions()
  },
  methods: {
    handleVpdnChange(vpdnId) {
      var data = this.vpdnOptionsMap[vpdnId]
      if (!data.edit) {
        data.ips = JSON.parse(data.ips).join('\n')
        data.edit = true
      }
      this.vpdnMap = data
    },

    fetchVpdnOptions() {
      this.loadingVpdnOptions = true
      findVpdnOptions(this.agentId).then((res) => {
        if (res.code === 0) {
          this.vpdnOptions = res.data
          res.data.forEach((element) => {
            this.vpdnOptionsMap[element.vpdn_id] = element
          })
        }
        this.loadingVpdnOptions = false
      })
    },

    checkIccids(rule, value, callback) {
      if (!value || !value.inputValue) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入卡号')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleValidate(e) {
      e.preventDefault()
      if (this.firstStepSubmitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.firstStepForm.validateFields((err, values) => {
        if (!err) {
          this.firstStepSubmitting = true
          validateAllSimCardsUpdateVpdn({
            iccids: values.iccids.data,
            top_agent_id: this.agentId
          }).then((res) => {
            if (res.code === 0) {
              if (res.data.is_valid) {
                // 关闭模态框
                this.isShowSubmitModal = true
                this.validIccids = values.iccids.data
                this.isShowFirstStepModal = false
              } else {
                this.firstStepForm.setFieldsValue({
                  iccids: { inputValue: res.data.result }
                })
                this.$nextTick(() => {
                  this.$refs.iccidInput.handleCards()
                })
              }
            }
            this.firstStepSubmitting = false
          })
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitForm.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          allSimCardsUpdateVpdn({
            ...values,
            top_agent_id: this.agentId,
            iccids: this.validIccids
          }).then((res) => {
            if (res.code === 0) {
              this.isShowSubmitModal = false
              // 告知父组件已完成
              this.$emit('completed')
              this.$success({
                title: '提交成功',
                content: (
                  <div>
                    <p>操作成功，具体操作结果请至 【系统查询】-【批量操作查询】 查看</p>
                  </div>
                )
              })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
