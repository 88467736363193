var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","width":800,"mask-closable":false,"ok-text":"下一步","ok-button-props":{ props: { loading: _vm.firstStepSubmitting } },"title":"VPDN分组配置"},on:{"ok":_vm.handleValidate},model:{value:(_vm.isShowFirstStepModal),callback:function ($$v) {_vm.isShowFirstStepModal=$$v},expression:"isShowFirstStepModal"}},[_c('a-row',[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.firstStepForm},on:{"submit":_vm.handleValidate}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"wrapper-col":{ span: 24 }}},[_c('common-card-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['iccids', {
                initialValue: { data: this.iccids },
                rules: [{ validator: _vm.checkIccids }],
              }]),expression:"['iccids', {\n                initialValue: { data: this.iccids },\n                rules: [{ validator: checkIccids }],\n              }]"}],ref:"iccidInput",attrs:{"rows":15}})],1)],1)],1)],1)],1),_c('a-modal',{attrs:{"centered":"","width":600,"mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"VPDN分组配置"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShowSubmitModal),callback:function ($$v) {_vm.isShowSubmitModal=$$v},expression:"isShowSubmitModal"}},[_c('a-row',[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 15 },"form":_vm.submitForm},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"已选择卡数"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(this.validIccids.length),callback:function ($$v) {_vm.$set(this.validIccids, "length", $$v)},expression:"this.validIccids.length"}})],1),_c('a-form-item',{attrs:{"label":"VPDN分组ID"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['vpdn_id', {
              rules: [
                { required: true, message: '请选择VPDN分组ID' }
              ]
            }]),expression:"['vpdn_id', {\n              rules: [\n                { required: true, message: '请选择VPDN分组ID' }\n              ]\n            }]"}],attrs:{"loading":_vm.loadingVpdnOptions,"allow-clear":""},on:{"change":_vm.handleVpdnChange}},_vm._l((_vm.vpdnOptions),function(option){return _c('a-select-option',{key:option.vpdn_id,attrs:{"value":option.vpdn_id}},[_vm._v(" "+_vm._s(option.vpdn_id)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"VPDN分组名称"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(_vm.vpdnMap.name),callback:function ($$v) {_vm.$set(_vm.vpdnMap, "name", $$v)},expression:"vpdnMap.name"}})],1),(_vm.submitForm.getFieldValue('vpdn_id')!='99999_E'&& _vm.submitForm.getFieldValue('vpdn_id')!='99999_A')?_c('a-form-item',{attrs:{"label":"VPDN IP地址"}},[_c('a-textarea',{staticStyle:{"height":"100px"},attrs:{"disabled":"","placeholder":""},model:{value:(_vm.vpdnMap.ips),callback:function ($$v) {_vm.$set(_vm.vpdnMap, "ips", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"vpdnMap.ips"}}),_c('div',[_vm._v("总数："+_vm._s(_vm.vpdnMap.ips_count))])],1):_vm._e(),_c('a-form-item',{attrs:{"label":"备注","extra":"*修改分组ID后则该卡可访问的IP地址会更新为最新分配的分组ID的IP地址，此操作可能会导致网络异常。"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
              rules: [
                { max: 200, message: '最多200个字符' } ]
            }]),expression:"['remark', {\n              rules: [\n                { max: 200, message: '最多200个字符' },\n              ]\n            }]"}],attrs:{"auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }